<script>
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'qr-pin-popup-mobile',
  components: {
    BaseButton,
    BaseMdl,
  },
  props: {
    pin: {
      type: Object,
      required: true,
    },
    reserveWorkspace: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    location() {
      const building = get(this.reserveWorkspace, 'building.name', '')
      const level = get(this.reserveWorkspace, 'level.name')

      return level + (level && building ? ', ' : '') + building
    },
  },
  methods: {
    onClick() {
      this.$emit('select-asset')
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl title="Select Workspace">
    <div class="mb-[5px] font-bold">
      {{ pin.name }}
    </div>
    <div class="whitespace-nowrap text-sm text-neutral-400">
      {{ location }}
    </div>
    <base-button full-width class="mt-[15px]" @click="onClick">
      Select
    </base-button>
  </base-mdl>
</template>
